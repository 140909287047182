import { AnimatePresence, motion } from 'framer-motion'
import { type ReactNode } from 'react'

type AnimatedShowHideProps = {
  readonly show: number | string | boolean | object | undefined | null
  readonly children: ReactNode
  readonly className?: string
}

const variants = {
  initial: { opacity: 0, height: 0 },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      height: { duration: 0.3 },
      opacity: { duration: 0.5 },
    },
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      height: { duration: 0.3 },
      opacity: { duration: 0.15 },
    },
  },
}

export const AnimatedShowHide = ({ show, children, className }: AnimatedShowHideProps) => {
  return (
    <AnimatePresence initial={false}>
      {show && (
        <motion.div {...variants} className={className}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
