import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { AnimatedShowHide } from '../../layout/AnimatedShowHide/AnimatedShowHide'

type HelpTextProps = {
  readonly type?: 'error' | 'warn'
  readonly className?: string
  readonly childClassName?: string
  readonly children?: ReactNode
}

export const HelpText = ({ type, className, childClassName, children }: HelpTextProps) => {
  return (
    <AnimatedShowHide
      show={children}
      className={twMerge(
        type === 'error' && 'text-error-600',
        type === 'warn' && 'text-warn',
        className,
      )}
    >
      <div className={childClassName}>{children}</div>
    </AnimatedShowHide>
  )
}
