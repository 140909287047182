'use client'

import { type InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import { FaEye, FaEyeSlash, FaX } from 'react-icons/fa6'
import { twMerge } from 'tailwind-merge'

import { useTranslation } from '../../../i18n/useTranslation'
import { HelpText } from '../../output/HelpText/HelpText'
import { InfoIcon } from '../../output/InfoIcon/InfoIcon'
import { Skeleton } from '../../output/Skeleton/Skeleton'
import { Button } from '../Button/Button'

type InputProps = {
  readonly className?: string
  readonly inputClassName?: string
  readonly label?: string
  readonly required?: boolean
  readonly disabled?: boolean
  readonly blockInteraction?: boolean
  readonly right?: React.ReactNode
  readonly type?: string
  readonly message?: string
  readonly error?: string | false
  readonly loading?: boolean
  readonly labelHidden?: boolean
  readonly help?: string
  readonly onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
} & InputHTMLAttributes<HTMLInputElement>

export const Input = ({
  className,
  inputClassName,
  label,
  required,
  disabled,
  blockInteraction,
  right,
  type,
  error,
  message,
  loading,
  labelHidden,
  help,
  onKeyDown,
  ...inputProps
}: InputProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const [internalState, setInternalState] = useState(inputProps.value)
  const [passwordShown, setPasswordShown] = useState(false)

  useEffect(() => {
    setInternalState(inputProps.value)
  }, [inputProps.value])

  useEffect(() => {
    inputProps.onChange?.({ target: ref.current } as React.ChangeEvent<HTMLInputElement>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalState])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(e)
      return
    }

    if (type === 'numeric') {
      const numericForbiddenChars = ['e', 'E', '-', '+', '.', ',']
      if (numericForbiddenChars.includes(e.key)) {
        e.preventDefault()
      }
    }
  }

  return (
    <div className={twMerge('flex flex-col', className)}>
      <div
        className={twMerge(
          'relative flex h-14 flex-row items-center rounded-md border-2 border-darker border-opacity-50 transition-all [&:has(:focus-within)]:border-opacity-100',
          disabled && 'opacity-70',
        )}
      >
        {loading && <Skeleton className='absolute -inset-[2px] w-[calc(100%+4px)] rounded-md' />}
        <input
          {...inputProps}
          value={internalState}
          aria-required={required}
          className={twMerge(
            'peer h-full w-full flex-1 rounded-md bg-transparent px-2 pt-4 placeholder-transparent !outline-0 [appearance:textfield] focus:outline-none [&::-ms-clear]:hidden [&::-ms-reveal]:hidden [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
            type === 'search' &&
              '[&::-webkit-search-cancel-button]:appearance-none [&::-webkit-search-decoration]:appearance-none [&::-webkit-search-results-button]:appearance-none [&::-webkit-search-results-decoration]:appearance-none',
            labelHidden && 'py-1',
            blockInteraction && 'pointer-events-none',
            inputClassName,
          )}
          ref={ref}
          type={
            type === 'numeric'
              ? 'number'
              : type === 'password'
              ? passwordShown
                ? 'text'
                : 'password'
              : type
          }
          onKeyDown={handleKeyDown}
          disabled={disabled || blockInteraction || loading}
          placeholder={label}
          aria-label={label}
          onChange={e => setInternalState(e.target.value)}
        />

        {right != null ? (
          right
        ) : type === 'password' ? (
          <Button
            icon
            variant='text'
            onClick={() => setPasswordShown(!passwordShown)}
            ariaLabel={passwordShown ? t.general.hidePassword : t.general.showPassword}
          >
            {passwordShown ? <FaEye /> : <FaEyeSlash />}
          </Button>
        ) : type === 'search' ? (
          typeof internalState === 'string' &&
          internalState.length > 0 && (
            <Button
              icon
              variant='text'
              ariaLabel={t.general.delete}
              onClick={() => setInternalState('')}
            >
              <FaX />
            </Button>
          )
        ) : null}

        {help && <InfoIcon label={help} />}

        <label
          className={twMerge(
            'pointer-events-none absolute left-2 top-1 h-fit text-xs opacity-100 transition-all peer-placeholder-shown:top-[0.85rem] peer-placeholder-shown:text-base peer-placeholder-shown:opacity-70 peer-focus:top-1 peer-focus:text-xs peer-focus:opacity-100',
            disabled && 'opacity-100 peer-placeholder-shown:opacity-100',
            labelHidden && 'sr-only',
          )}
        >
          {label} {required && '*'}
        </label>
      </div>

      <HelpText>{message}</HelpText>
      <HelpText type='error'>{error}</HelpText>
    </div>
  )
}
