import { Listbox as HUIListbox, Transition } from '@headlessui/react'
import { Fragment, type ReactNode, useEffect, useState } from 'react'
import { FaCheck, FaChevronDown, FaX } from 'react-icons/fa6'
import { twMerge } from 'tailwind-merge'

import { type Identifier } from '../../../domain/identifier'
import { useTranslation } from '../../../i18n/useTranslation'
import { HelpText } from '../../output/HelpText/HelpText'
import { ResourcePill } from '../../output/ResourcePill/ResourcePill'
import { Button } from '../Button/Button'
import { Input } from '../Input/Input'

type ListboxProps<T extends string> = {
  readonly className?: string
  readonly label?: string
  readonly required?: boolean
  readonly options: readonly {
    id: T
    label: string
    resource?: Identifier & { readonly displayName?: string }
    disabled?: boolean
    right?: ReactNode
  }[]
  readonly value?: T | null
  readonly onChange?: (value: T) => void
  readonly onBlur?: (e: FocusEvent) => void
  readonly emptyText?: string
  readonly disabled?: boolean
  readonly error?: ReactNode
  readonly loading?: boolean
  readonly labelHidden?: boolean
  readonly allowDeselect?: boolean
  readonly help?: string
}

export const Listbox = <T extends string>({
  className,
  label,
  required,
  options,
  value,
  onChange,
  onBlur,
  emptyText,
  disabled,
  error,
  loading,
  labelHidden,
  allowDeselect,
  help,
}: ListboxProps<T>) => {
  const [internalState, setInternalState] = useState(value)
  const { t } = useTranslation()

  useEffect(() => {
    if (internalState != null) {
      onChange?.(internalState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalState])

  useEffect(() => {
    setInternalState(value)
  }, [value])

  return (
    <div className={twMerge('relative flex flex-col', className)}>
      <HUIListbox
        value={internalState}
        onChange={setInternalState}
        disabled={disabled || loading || options.length === 0}
      >
        <div className='relative'>
          <HUIListbox.Button
            className={twMerge('w-full cursor-pointer', loading && 'cursor-default')}
            aria-label={label}
            onBlur={e => onBlur?.(e.nativeEvent)}
          >
            <Input
              value={options.find(o => o.id === internalState)?.label ?? ''}
              label={options.length === 0 ? emptyText ?? t.general.noItems : label}
              tabIndex={-1}
              required={required}
              aria-hidden='true'
              disabled={disabled}
              blockInteraction
              loading={loading}
              right={<FaChevronDown className='h-5 w-8 px-2' />}
              help={help}
              readOnly
              labelHidden={labelHidden}
            />
          </HUIListbox.Button>
          {allowDeselect && internalState && (
            <Button
              icon
              className='absolute inset-y-1 right-8 z-[1]'
              variant='text'
              ariaLabel={t.general.delete}
              onClick={() => setInternalState('' as T)}
            >
              <FaX />
            </Button>
          )}
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <HUIListbox.Options className='absolute z-[1] mt-1 max-h-60 w-full overflow-auto rounded-md bg-foreground py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:ring-gray-600 sm:text-sm'>
              {options.map((el, i) => (
                <HUIListbox.Option
                  key={i}
                  className={({ active }) =>
                    twMerge(
                      'relative cursor-default select-none py-2 pl-10 pr-4',
                      active && 'bg-hover',
                      el.disabled && 'opacity-50',
                    )
                  }
                  value={el.id}
                  disabled={el.disabled}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={twMerge(
                          'flex items-center justify-between gap-4',
                          selected ? 'font-medium' : 'font-normal',
                        )}
                      >
                        {el.resource ? (
                          <ResourcePill textOnly condensed resource={el.resource} />
                        ) : (
                          <span className='truncate'>{el.label}</span>
                        )}
                        {el.right}
                      </span>

                      {selected ? (
                        <span className='absolute inset-y-0 left-0 flex items-center pl-3'>
                          <FaCheck className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </HUIListbox.Option>
              ))}
            </HUIListbox.Options>
          </Transition>
        </div>
      </HUIListbox>

      <HelpText type='error'>{error}</HelpText>
    </div>
  )
}
