'use client'

import { FaArrowUpRightFromSquare } from 'react-icons/fa6'
import { twMerge } from 'tailwind-merge'

import { type Identifier, isProjectScopeIdentifier } from '../../../domain/identifier'
import { Link } from '../../../i18n/Navigation'
import { HoverLabel } from '../HoverLabel/HoverLabel'
import { Skeleton } from '../Skeleton/Skeleton'

type ResourcePillProps = {
  readonly className?: string
  readonly type?: { name: string; icon: React.ReactElement }
  readonly baseUrl?: string
  readonly resource?: Identifier & { readonly displayName?: string }
  readonly icon?: React.ReactNode
  readonly condensed?: boolean
  readonly textOnly?: boolean
}

export const ResourcePill = ({
  className,
  type,
  baseUrl,
  resource,
  icon,
  condensed,
  textOnly,
}: ResourcePillProps) => {
  const content = (
    <span className='flex flex-col'>
      <span className='flex flex-row items-center gap-1 whitespace-nowrap'>
        {type && (
          <>
            <span className={twMerge(condensed && 'leading-[normal]', 'hidden md:block')}>
              {type.name}:{' '}
            </span>
            <span className='mr-1 md:hidden'>
              <HoverLabel label={type.name}>{type.icon}</HoverLabel>
            </span>
          </>
        )}
        {resource ? (
          <span
            className={twMerge(
              'block max-w-[180px] overflow-hidden text-ellipsis md:max-w-[250px]',
              condensed && 'leading-[normal]',
            )}
          >
            {resource.displayName}
          </span>
        ) : (
          <Skeleton className='w-32' containerClassName='inline-block' />
        )}
      </span>
      <span
        className={twMerge(
          'pointer-events-none block max-w-[180px] overflow-hidden text-ellipsis whitespace-nowrap text-xs opacity-70 md:max-w-[250px]',
          condensed && 'leading-[normal]',
        )}
      >
        {resource?.name ?? <Skeleton className='w-32' containerClassName='inline-block' />}
      </span>
    </span>
  )

  const classNames = twMerge(
    'inline-flex flex-row items-center gap-4 rounded-full border-[2px] border-regular border-opacity-60 bg-foreground px-6 py-1 md:px-8',
    condensed && 'px-4 py-[0.175rem] md:px-6',
    textOnly && 'rounded-sm border-0 bg-transparent px-0 py-0 md:px-0',
    className,
  )

  return baseUrl != null ? (
    <Link
      href={
        resource
          ? isProjectScopeIdentifier(resource)
            ? `${baseUrl}/${resource.projectName}/${resource.name}`
            : `${baseUrl}/${resource.name}`
          : baseUrl
      }
      className={twMerge(classNames, 'relative cursor-pointer')}
    >
      {content}
      {icon ?? <FaArrowUpRightFromSquare className='inline-block text-sm text-accent-700' />}
    </Link>
  ) : (
    <span className={classNames}>
      {content}
      {icon}
    </span>
  )
}
