import { FaQuestion } from 'react-icons/fa6'
import { twMerge } from 'tailwind-merge'

import { HoverLabel } from '../HoverLabel/HoverLabel'

type InfoIconProps = {
  label: string
  variant?: 'outlined'
  anchor?: 'start' | 'center' | 'end'
  direction?: 'top' | 'bottom' | 'left' | 'right'
  className?: string
}

export const InfoIcon = ({ label, variant, anchor, direction, className }: InfoIconProps) => (
  <HoverLabel
    anchor={anchor ?? 'end'}
    direction={direction ?? 'bottom'}
    hoverLabelClassName='w-96 max-w-full'
    label={label}
  >
    <div
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      className={twMerge(
        'relative flex h-9 w-9 flex-row items-center justify-center rounded-full bg-transparent p-2 text-secondary before:rounded-full',
        variant === 'outlined' &&
          'before:absolute before:inset-0 before:border-2 before:border-lighter before:content-[""] dark:before:border-secondary',
        className,
      )}
    >
      <FaQuestion />
    </div>
  </HoverLabel>
)
