'use client'

import {
  type HTMLAttributes,
  type ReactElement,
  type ReactNode,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'

import { minmax } from '../../../helpers/numbers'
import { useIsSsr } from '../../../hooks/useIsSsr'
import { type Override } from '../../../types/helpers'

type HoverLabelProps = Override<
  HTMLAttributes<HTMLDivElement>,
  {
    readonly label: ReactNode
    readonly direction?: 'top' | 'bottom' | 'left' | 'right'
    readonly anchor?: 'start' | 'center' | 'end'
    readonly children: ReactElement
    readonly hoverLabelClassName?: string
  }
>

export const HoverLabel = ({
  label,
  direction = 'bottom',
  anchor = 'center',
  children,
  hoverLabelClassName,
  ...divProps
}: HoverLabelProps) => {
  const id = useId()
  const isSsr = useIsSsr()

  const [labelRef, setLabelRef] = useState<HTMLDivElement | null>(null)
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)

  const [labelRect, setLabelRect] = useState<DOMRect | undefined>(undefined)
  const [containerRect, setContainerRect] = useState<DOMRect | undefined>(undefined)
  const [labelShown, setLabelShown] = useState(false)

  const { left, top } = useMemo(() => {
    if (!labelRect || !containerRect) {
      return { left: 0, top: 0 }
    }

    let left = 0,
      top = 0

    switch (direction) {
      case 'top':
        left = containerRect.left
        top = containerRect.top - labelRect.height - 8
        break
      case 'bottom':
        left = containerRect.left
        top = containerRect.top + containerRect.height + 8
        break
      case 'left':
        left = containerRect.left - labelRect.width - 8
        top = containerRect.top
        break
      case 'right':
        left = containerRect.left + containerRect.width + 8
        top = containerRect.top
        break
    }

    switch (anchor) {
      case 'start':
        break
      case 'center':
        if (direction === 'top' || direction === 'bottom') {
          left -= labelRect.width / 2 - containerRect.width / 2
        } else {
          top -= labelRect.height / 2 - containerRect.height / 2
        }
        break
      case 'end':
        if (direction === 'top' || direction === 'bottom') {
          left -= labelRect.width - containerRect.width
        } else {
          top -= labelRect.height - containerRect.height
        }
        break
    }

    return {
      left: minmax(10, document.body.clientWidth - labelRect.width - 10, left),
      top: minmax(10, document.body.clientHeight - labelRect.height - 10, top),
    }
  }, [labelRect, containerRect, direction, anchor])

  useEffect(() => {
    setContainerRect(containerRef?.getBoundingClientRect())
    setLabelRect(labelRef?.getBoundingClientRect())
  }, [containerRef, labelRef])

  const showHoverLabel = () => {
    setLabelShown(true)
    setContainerRect(containerRef?.getBoundingClientRect())
    setLabelRect(labelRef?.getBoundingClientRect())
  }

  const hideHoverLabel = () => {
    setLabelShown(false)
  }

  return (
    <>
      <div
        {...divProps}
        ref={ref => setContainerRef(ref)}
        className={twMerge('w-fit', divProps.className)}
        onFocus={e => {
          divProps.onFocus?.(e)
          showHoverLabel()
        }}
        onMouseOver={e => {
          divProps.onMouseOver?.(e)
          showHoverLabel()
        }}
        onBlur={e => {
          divProps.onBlur?.(e)
          hideHoverLabel()
        }}
        onMouseLeave={e => {
          divProps.onMouseLeave?.(e)
          hideHoverLabel()
        }}
      >
        <div aria-describedby={id}>{children}</div>
      </div>

      {!isSsr &&
        createPortal(
          <div
            role='tooltip'
            ref={ref => setLabelRef(ref)}
            id={id}
            className={twMerge(
              'pointer-events-none invisible fixed z-10 inline-block rounded-md border-regular bg-foreground-lighter px-4 py-2 opacity-0 shadow-md transition-opacity duration-200',
              labelShown && 'visible opacity-100',
              hoverLabelClassName,
            )}
            style={{
              left,
              top,
            }}
          >
            {label}
          </div>,
          document?.body,
        )}
    </>
  )
}
